import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { Component, NgModule, inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { OnboardingModule } from '@logic-suite/onboarding';
import { AUTH_TOKEN, AuthGuard, AuthModule } from '@logic-suite/shared/auth';
import { CacheModule } from '@logic-suite/shared/cache';
import { LoaderInterceptor, LoaderModule } from '@logic-suite/shared/components/loader';
import { ErrorHandlerModule } from '@logic-suite/shared/error';
import { I18nRootModule, START_OF_WEEK, createTranslateLoader } from '@logic-suite/shared/i18n';
import { PipesModule } from '@logic-suite/shared/pipes';
import { assignEnv, getEnv } from '@logic-suite/shared/utils';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { FLXIconsModule } from './shared/icons/icons.module';
import { PolicyModule } from './shared/policy/policy.module';
import { E403Component } from './views/error/e403.component';
import { E500Component } from './views/error/e500.component';
import { ErrorInterceptor } from './views/error/error.interceptor';
import { CustomerInterceptor } from './views/user/profile/customer.interceptor';
import { ProfileService } from './views/user/profile/profile.service';

assignEnv(environment);

/** Dummy component required for E2E testing */
@Component({
    selector: 'flx-public', template: `<!-- Test component -->`,
    standalone: false
})
export class PublicComponent {}

@NgModule({
  declarations: [AppComponent, PublicComponent, E403Component],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(
      [
        // Routes to the main views in the app. Represented by the bottom nav icons
        {
          path: 'home',
          loadChildren: () => import('./views/home/home.module').then(m => m.HomeModule),
          canActivate: [AuthGuard],
          title: 'Flex',
        },
        {
          path: 'colleagues',
          loadChildren: () => import('./views/colleagues/colleagues.module').then(m => m.ColleaguesModule),
          canActivate: [AuthGuard],
          title: 'Colleagues | Flex',
        },
        {
          path: 'user',
          loadChildren: () => import('./views/user/user.module').then(m => m.UserModule),
          canActivate: [AuthGuard],
          title: 'Profile | Flex',
        },
        // Error pages
        { path: '403', component: E403Component, title: '403 | Flex' },
        { path: '500', component: E500Component, title: '500 | Flex' },
        // This route is not in use by anything other than E2E testing
        { path: 'hidden', component: PublicComponent },
        // Catch all redirect to home
        { path: '**', redirectTo: 'home' },
      ],
      {
        // relativeLinkResolution: 'legacy',
        onSameUrlNavigation: 'reload',
        initialNavigation: 'enabledBlocking',
      },
    ),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => createTranslateLoader(http, ['flx', 'onboarding']),
        deps: [HttpClient],
      },
    }),
    ReactiveFormsModule,
    AuthModule,
    I18nRootModule,
    CacheModule,
    FLXIconsModule,
    ErrorHandlerModule,
    PolicyModule,
    LoaderModule,
    OnboardingModule, // Handles all onboarding related stuff
    PipesModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
  ],
  providers: [
    { provide: AUTH_TOKEN, useValue: getEnv('auth') },
    { provide: START_OF_WEEK, useValue: 1 },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CustomerInterceptor, multi: true },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {
  // Required to load employee initially and as the first request,
  // but this could probably be done using a resolver instead
  profile = inject(ProfileService);
}
