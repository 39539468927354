try {
  self["workbox:window:7.2.0"] && _();
} catch (n) {}
function n(n, t) {
  return new Promise(function (r) {
    var e = new MessageChannel();
    e.port1.onmessage = function (n) {
      r(n.data);
    }, n.postMessage(t, [e.port2]);
  });
}
function t(n) {
  var t = function (n, t) {
    if ("object" != typeof n || !n) return n;
    var r = n[Symbol.toPrimitive];
    if (void 0 !== r) {
      var e = r.call(n, t || "default");
      if ("object" != typeof e) return e;
      throw new TypeError("@@toPrimitive must return a primitive value.");
    }
    return ("string" === t ? String : Number)(n);
  }(n, "string");
  return "symbol" == typeof t ? t : t + "";
}
function r(n, r) {
  for (var e = 0; e < r.length; e++) {
    var i = r[e];
    i.enumerable = i.enumerable || !1, i.configurable = !0, "value" in i && (i.writable = !0), Object.defineProperty(n, t(i.key), i);
  }
}
function e(n, t) {
  return e = Object.setPrototypeOf ? Object.setPrototypeOf.bind() : function (n, t) {
    return n.__proto__ = t, n;
  }, e(n, t);
}
function i(n, t) {
  (null == t || t > n.length) && (t = n.length);
  for (var r = 0, e = new Array(t); r < t; r++) e[r] = n[r];
  return e;
}
function o(n, t) {
  var r = "undefined" != typeof Symbol && n[Symbol.iterator] || n["@@iterator"];
  if (r) return (r = r.call(n)).next.bind(r);
  if (Array.isArray(n) || (r = function (n, t) {
    if (n) {
      if ("string" == typeof n) return i(n, t);
      var r = Object.prototype.toString.call(n).slice(8, -1);
      return "Object" === r && n.constructor && (r = n.constructor.name), "Map" === r || "Set" === r ? Array.from(n) : "Arguments" === r || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(r) ? i(n, t) : void 0;
    }
  }(n)) || t && n && "number" == typeof n.length) {
    r && (n = r);
    var e = 0;
    return function () {
      return e >= n.length ? {
        done: !0
      } : {
        done: !1,
        value: n[e++]
      };
    };
  }
  throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
try {
  self["workbox:core:7.2.0"] && _();
} catch (n) {}
var u = function () {
  var n = this;
  this.promise = new Promise(function (t, r) {
    n.resolve = t, n.reject = r;
  });
};
function a(n, t) {
  var r = location.href;
  return new URL(n, r).href === new URL(t, r).href;
}
var c = function (n, t) {
  this.type = n, Object.assign(this, t);
};
function f(n, t, r) {
  return r ? t ? t(n) : n : (n && n.then || (n = Promise.resolve(n)), t ? n.then(t) : n);
}
function s() {}
var v = {
  type: "SKIP_WAITING"
};
function h(n, t) {
  if (!t) return n && n.then ? n.then(s) : Promise.resolve();
}
var l = function (t) {
  function i(n, r) {
    var e, i;
    return void 0 === r && (r = {}), (e = t.call(this) || this).nn = {}, e.tn = 0, e.rn = new u(), e.en = new u(), e.on = new u(), e.un = 0, e.an = new Set(), e.cn = function () {
      var n = e.fn,
        t = n.installing;
      e.tn > 0 || !a(t.scriptURL, e.sn.toString()) || performance.now() > e.un + 6e4 ? (e.vn = t, n.removeEventListener("updatefound", e.cn)) : (e.hn = t, e.an.add(t), e.rn.resolve(t)), ++e.tn, t.addEventListener("statechange", e.ln);
    }, e.ln = function (n) {
      var t = e.fn,
        r = n.target,
        i = r.state,
        o = r === e.vn,
        u = {
          sw: r,
          isExternal: o,
          originalEvent: n
        };
      !o && e.mn && (u.isUpdate = !0), e.dispatchEvent(new c(i, u)), "installed" === i ? e.wn = self.setTimeout(function () {
        "installed" === i && t.waiting === r && e.dispatchEvent(new c("waiting", u));
      }, 200) : "activating" === i && (clearTimeout(e.wn), o || e.en.resolve(r));
    }, e.yn = function (n) {
      var t = e.hn,
        r = t !== navigator.serviceWorker.controller;
      e.dispatchEvent(new c("controlling", {
        isExternal: r,
        originalEvent: n,
        sw: t,
        isUpdate: e.mn
      })), r || e.on.resolve(t);
    }, e.gn = (i = function (n) {
      var t = n.data,
        r = n.ports,
        i = n.source;
      return f(e.getSW(), function () {
        e.an.has(i) && e.dispatchEvent(new c("message", {
          data: t,
          originalEvent: n,
          ports: r,
          sw: i
        }));
      });
    }, function () {
      for (var n = [], t = 0; t < arguments.length; t++) n[t] = arguments[t];
      try {
        return Promise.resolve(i.apply(this, n));
      } catch (n) {
        return Promise.reject(n);
      }
    }), e.sn = n, e.nn = r, navigator.serviceWorker.addEventListener("message", e.gn), e;
  }
  var o, s;
  s = t, (o = i).prototype = Object.create(s.prototype), o.prototype.constructor = o, e(o, s);
  var l,
    m,
    w,
    y = i.prototype;
  return y.register = function (n) {
    var t = (void 0 === n ? {} : n).immediate,
      r = void 0 !== t && t;
    try {
      var e = this;
      return f(function (n, t) {
        var r = n();
        if (r && r.then) return r.then(t);
        return t(r);
      }(function () {
        if (!r && "complete" !== document.readyState) return h(new Promise(function (n) {
          return window.addEventListener("load", n);
        }));
      }, function () {
        return e.mn = Boolean(navigator.serviceWorker.controller), e.dn = e.pn(), f(e.bn(), function (n) {
          e.fn = n, e.dn && (e.hn = e.dn, e.en.resolve(e.dn), e.on.resolve(e.dn), e.dn.addEventListener("statechange", e.ln, {
            once: !0
          }));
          var t = e.fn.waiting;
          return t && a(t.scriptURL, e.sn.toString()) && (e.hn = t, Promise.resolve().then(function () {
            e.dispatchEvent(new c("waiting", {
              sw: t,
              wasWaitingBeforeRegister: !0
            }));
          }).then(function () {})), e.hn && (e.rn.resolve(e.hn), e.an.add(e.hn)), e.fn.addEventListener("updatefound", e.cn), navigator.serviceWorker.addEventListener("controllerchange", e.yn), e.fn;
        });
      }));
    } catch (n) {
      return Promise.reject(n);
    }
  }, y.update = function () {
    try {
      return this.fn ? f(h(this.fn.update())) : f();
    } catch (n) {
      return Promise.reject(n);
    }
  }, y.getSW = function () {
    return void 0 !== this.hn ? Promise.resolve(this.hn) : this.rn.promise;
  }, y.messageSW = function (t) {
    try {
      return f(this.getSW(), function (r) {
        return n(r, t);
      });
    } catch (n) {
      return Promise.reject(n);
    }
  }, y.messageSkipWaiting = function () {
    this.fn && this.fn.waiting && n(this.fn.waiting, v);
  }, y.pn = function () {
    var n = navigator.serviceWorker.controller;
    return n && a(n.scriptURL, this.sn.toString()) ? n : void 0;
  }, y.bn = function () {
    try {
      var n = this;
      return f(function (n, t) {
        try {
          var r = n();
        } catch (n) {
          return t(n);
        }
        if (r && r.then) return r.then(void 0, t);
        return r;
      }(function () {
        return f(navigator.serviceWorker.register(n.sn, n.nn), function (t) {
          return n.un = performance.now(), t;
        });
      }, function (n) {
        throw n;
      }));
    } catch (n) {
      return Promise.reject(n);
    }
  }, l = i, (m = [{
    key: "active",
    get: function () {
      return this.en.promise;
    }
  }, {
    key: "controlling",
    get: function () {
      return this.on.promise;
    }
  }]) && r(l.prototype, m), w && r(l, w), Object.defineProperty(l, "prototype", {
    writable: !1
  }), l;
}(function () {
  function n() {
    this.Pn = new Map();
  }
  var t = n.prototype;
  return t.addEventListener = function (n, t) {
    this.jn(n).add(t);
  }, t.removeEventListener = function (n, t) {
    this.jn(n).delete(t);
  }, t.dispatchEvent = function (n) {
    n.target = this;
    for (var t, r = o(this.jn(n.type)); !(t = r()).done;) {
      (0, t.value)(n);
    }
  }, t.jn = function (n) {
    return this.Pn.has(n) || this.Pn.set(n, new Set()), this.Pn.get(n);
  }, n;
}());
export { l as Workbox, c as WorkboxEvent, n as messageSW };
