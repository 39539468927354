import {
  ChangeDetectionStrategy,
  Component,
  computed,
  ElementRef,
  HostBinding,
  HostListener,
  inject,
  OnDestroy,
  OnInit,
  signal,
  ViewChild,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { endOfDay, startOfDay, startOfSecond } from 'date-fns';
import { combineLatest, firstValueFrom, Subscription } from 'rxjs';
import { delay, filter, first, switchMap, tap } from 'rxjs/operators';

import { OnboardingConfig, OnboardingService, TourState } from '@logic-suite/onboarding';
import { dropdown } from '@logic-suite/shared/animations';
import { AppService } from '@logic-suite/shared/app.service';
import { AuthService } from '@logic-suite/shared/auth';
import { LanguageService } from '@logic-suite/shared/i18n';
import { TelemetryService } from '@logic-suite/shared/telemetry';
import { ThemeService } from '@logic-suite/shared/theme';
import { getEnv } from '@logic-suite/shared/utils/getEnv';

import { DomSanitizer } from '@angular/platform-browser';
import { Connectivity, ConnectivityService } from '@logic-suite/shared/connectivity/connectivity.service';
import { Browser, logger, urlB64ToUint8Array } from '@logic-suite/shared/utils';
import { BookingService } from './shared/booking/booking.service';
import { Features, PolicyService } from './shared/policy/policy.service';
import { EmployeeService } from './views/user/profile/employee.service';
import { Employee, EmployeeCustomer, LogoUrl } from './views/user/profile/profile.model';
import { ProfileService } from './views/user/profile/profile.service';

const VAPID_PUBLIC_KEY = 'BDcVRG9DVLXmdRHvd64Wfebxpky4HG3l1LBkTZxCUWk17X0ZnvKAZ43tJKLvj_-zcioGDWVAKBC1NySWA4ad-_w';

@Component({
    selector: 'flx-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [dropdown],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AppComponent implements OnInit, OnDestroy {
  private auth = inject(AuthService);
  private telemetry = inject(TelemetryService);
  private language = inject(LanguageService);
  private dom = inject(DomSanitizer);
  private employee = inject(EmployeeService);
  private app = inject(AppService);
  private booking = inject(BookingService);
  private router = inject(Router);
  private onboarder = inject(OnboardingService);
  private policy = inject(PolicyService);
  private profile = inject(ProfileService);
  private themeService = inject(ThemeService);
  private connectivity = inject(ConnectivityService);

  isOnline = signal(true);

  @ViewChild('menuTrigger', { read: ElementRef }) menuTrigger!: ElementRef<HTMLElement>;
  emp!: Employee;
  selectedCustomer!: EmployeeCustomer;
  env = getEnv('env');
  subscriptions: Subscription[] = [];

  showMenu = false;

  colorScheme = 'dark';
  prefersTheme: keyof LogoUrl =
    window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
  theme = signal<keyof LogoUrl>('dark');
  themeBase = computed(() => (['dark', 'light'].includes(this.theme()) ? this.theme() : this.prefersTheme));
  isLoggedIn = signal(false);
  feature = Features;

  activeFlow = signal<OnboardingConfig | undefined>(undefined);
  @HostBinding('class.tour-available')
  get tourAvailable() {
    const flow = this.activeFlow();
    if (!flow || this.isTourActive) return false;
    return flow.flowName && !flow._isPlaying && flow.flowType !== 'collect';
  }

  @HostBinding('class.showing-tutorial')
  isTourActive = false;

  b = Browser.getBrowser();
  @HostBinding('class')
  classNames = `UA_${this.b.browser} UV_${this.b.version} ${this.b.isMobile ? 'mobile' : 'desktop'}`;

  constructor() {
    this.subscriptions.push(this.auth.isLoggedIn$.subscribe(val => this.isLoggedIn.set(val)));

    // Preload cars if policy is set. This will speed up the display of available cars on the receipt panel
    this.policy.hasFeatureAsync(Features.Parking).then(flag => {
      if (flag) firstValueFrom(this.profile.getCars()).then(cars => ({}));
    });

    this.subscriptions.push(
      this.connectivity.connectivityChanged$.subscribe((connectivity) => {
        this.isOnline.set(connectivity === Connectivity.ONLINE);
      }),
    );

    // Setup Push Notifications
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then(async (reg: ServiceWorkerRegistration) => {
        let sub = await reg.pushManager.getSubscription();
        if (!sub) {
          // Not a subscriber. Ask for permission
          const permission = await Notification.requestPermission();
          if (permission === 'denied') return;
        }

        // Permission granted. Subscribe to notifications
        sub = await reg.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: urlB64ToUint8Array(VAPID_PUBLIC_KEY),
        });
        await firstValueFrom(this.profile.subscribeToPushNotifications(sub));
      });
    } else {
      logger('Notifications not supported', 'App', true);
    }
  }

  ngOnInit() {
    // Start telemetry
    this.telemetry.init();

    this.app.setApplicationID(10);

    this.subscriptions.push(
      combineLatest([this.employee.getEmployee(), this.employee.getCustomer$().pipe(filter(cust => !!cust))]).subscribe(
        ([emp, customer]) => {
          this.emp = emp;
          this.emp.customers =
            this.emp.customers?.map(c => {
              c.logoUrl = {
                light:
                  typeof c.logoUrl.light === 'string'
                    ? this.dom.bypassSecurityTrustResourceUrl(c.logoUrl.light)
                    : c.logoUrl.light,
                dark:
                  typeof c.logoUrl.dark === 'string'
                    ? this.dom.bypassSecurityTrustResourceUrl(c.logoUrl.dark)
                    : c.logoUrl.dark,
              };
              return c;
            }) ?? [];
          this.selectedCustomer =
            this.emp.customers.find(c => c.customerID === customer.customerID) || this.emp.customers[0];
        },
      ),
    );

    // Listen for changes in current color scheme
    this.themeService.listen(true, 'colorScheme');
    this.subscriptions.push(this.themeService.themeChanged.subscribe(val => this.theme.set(val as keyof LogoUrl)));

    // Check which route to prioritize on initial load
    const d = this.booking.getSelectedDay();
    this.subscriptions.push(
      this.router.events
        .pipe(
          filter(e => e instanceof NavigationEnd && !['callback', 'login'].some(u => e.url.includes(u))),
          first(),
          filter(e => {
            const navEnd = e as NavigationEnd;
            return navEnd.url.startsWith('/home/check-in') || navEnd.urlAfterRedirects.startsWith('/home/check-in');
          }),
          switchMap(e => this.booking.getBookings(startOfDay(d).getTime(), startOfSecond(endOfDay(d)).getTime())),
        )
        .subscribe(booking => {
          if (booking.length > 0) {
            // We have bookings for today
            this.router.navigate(['/home/day']);
          }
        }),
    );

    // Activate any pending onboarding
    this.policy.hasFeatureAsync(Features.Onboarding).then(flag => {
      if (flag) {
        // React to tourstate changes
        this.subscriptions.push(
          this.onboarder.tourState$
            .pipe(
              // Set tourstate flag locally
              tap(state => (this.isTourActive = state === TourState.ACTIVE)),
              // Load new flow immediately when previous flow is done
              filter(state => state === TourState.INACTIVE),
              delay(500), // Allow backend time to set flowPlayed if this is called right after another flow
              switchMap(() => this.onboarder.getActiveFlow()),
              // distinctUntilChanged((a, b) => a?.flowID === b?.flowID),
            )
            .subscribe(flow => {
              if (flow?.flowPlayedMs == null && flow?.flowType === 'collect') {
                // Play immediately if this is a collect flow
                this.onboarder.play();
              }
            }),
        );

        // Store the currently active tour
        this.onboarder.activeFlow$.subscribe((flow: OnboardingConfig) => this.activeFlow.set(flow));
      }
    });
  }

  playTour() {
    this.onboarder.play();
  }

  endTour() {
    this.onboarder.endTour();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  toggleMenu(evt: MouseEvent) {
    evt.stopPropagation();
    this.showMenu = !this.showMenu;
  }

  setLanguage($event: string) {
    return this.language.change($event);
  }

  preventClose($event: MouseEvent) {
    $event.stopPropagation();
  }

  preventDefault($event: MouseEvent) {
    $event.preventDefault();
  }

  @HostListener('window:click', ['$event'])
  onClick(evt: MouseEvent) {
    const clickOnTrigger =
      this.menuTrigger && (evt.target as HTMLElement).closest('a') === this.menuTrigger.nativeElement;
    if (this.showMenu && !clickOnTrigger) {
      setTimeout(() => (this.showMenu = false));
    }
  }

  selectCustomer(customer: EmployeeCustomer) {
    this.app.invalidateCache();
    this.employee.setCustomer(customer);
    const thisUrl = this.router.url;
    this.router.navigateByUrl('/hidden', { skipLocationChange: true }).then(() => this.router.navigate([thisUrl]));
  }
}
